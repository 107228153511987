import { Translations } from './utils/translations';
import { MEMBERS_AREA_DEF_ID, DAILY_TIMETABLE_WIDGET_CONTROLLER_ID, PageId } from './constants';
import { addBookingsMembersArea, installMembersArea } from './utils/members-area-actions';
import { addBookingsPagesAsPanel, createBookCheckoutState } from './utils/pages-panel-actions';
import { handleLightMigration, handleMigration, shouldProposeMigration } from './utils/migrate-actions';
import { getCurrentVersion } from './utils/ci-actions';
import { proposeMigration } from './utils/migration-modals';
import {
  getStateBoxByBookingsAppBuilderWidget,
  getAllBookingsPages,
  getBookingsData,
  getPageData,
  isBookingsSplitted,
  onRemoveApp,
  removePage,
  getBookingsAppBuilderWidgetByChildComponentRef,
  isNoBookingsPagesInstalled,
} from './utils/editor-sdk-actions';
import {
  ConnectionBuilder,
  ConnectionBuilderFactory,
} from './utils/manifest-definitions';
import { addBookingsPage } from './utils/pages-actions';

enum SlotRepeaterState {
  LOADING = "loaderState",
  LIST = "slotRepeaterState",
  NO_CLASSES = "noClassesState"
}

export const createEditorScript = (withSplit, withMigrationProposal) => {
  let instance, appToken, sdk, locale, isAdi, isProposeMigrationNeeded;
  const bookingsDashboardPanel = 'bookings/scheduler/owner/offerings';
  const manageBookingsEvent = 'manageBookings';
  const manageStateEvent = 'manageState';
  const deleteBookingsEvent = 'deleteBookings';
  const deletePageEvent = 'deletePage';
  const pageChangedEvent = 'focusedPageChanged';
  const widgetGfppClicked = 'widgetGfppClicked';
  const componentGfppClicked = 'componentGfppClicked';
  const translation = new Translations();

  return {
    editorReady: async (_editorSDK, _appToken, options) => {
      locale = await _editorSDK.environment.getLocale();
      const bookingsData = await getBookingsData(_editorSDK, appToken);
      instance = bookingsData.instance;
      await translation.editorInit(locale, await getCurrentVersion(_editorSDK, _appToken));
      return new Promise(async (resolve) => {
        appToken = _appToken;
        sdk = _editorSDK;
        isAdi = options.origin && options.origin.type === 'ADI';
        if (await isBookingsSplitted(sdk)) {
          await addBookingsPagesAsPanel(sdk, appToken);
        }
        // if (!isAdi && await isNoBookingsPagesInstalled(sdk)) { // todo - return only if theres a problem with missing pages
        //   console.log('No Bookings pages found, Installing Bookings Pages');
        //   await addBookingsPage(PageId.BOOKINGS_LIST,sdk,appToken);
        //   await addBookingsPage(PageId.BOOKINGS_CHECKOUT,sdk,appToken);
        //   await addBookingsPagesAsPanel(sdk, appToken);
        // }
        if (options && options.firstInstall) {
          if (withSplit) {
            await addBookingsPagesAsPanel(sdk, appToken);
          }
          if (!isAdi) {
            await installMembersArea(sdk, appToken);
          }
        } else if (withMigrationProposal) {
          await sdk.document.application.registerToCustomEvents(appToken, {eventTypes: [pageChangedEvent]});
          isProposeMigrationNeeded = shouldProposeMigration(sdk, isAdi, appToken, instance);
        }
        await createBookCheckoutState(sdk, appToken);
        resolve();
      });
    },
    getAppManifest: () => {
      const manageBookingsAction = {
        label: translation.t('bookings-pages.actions.manage'),
        actionId: manageBookingsEvent
      };
      const manageStateAction = {
        label: translation.t('bookings.daily-timetable.component.label.SwitchState'),
        actionId: manageStateEvent
      };
      const connectionFactory = new ConnectionBuilderFactory(translation, manageBookingsAction);
      const mainAppConnection = new ConnectionBuilder()
        .withLabel(translation.t('bookings.daily-timetable.component.label.Timetable'))
        .withMainAction1(manageBookingsAction)
        .withMainAction2(manageStateAction)
        .withHelpId('0b516ee7-36ca-48c8-9a1d-95ce926802ef')
        .withConnections({
          dateRangeLabel: connectionFactory.uneditableText(translation.t('bookings.daily-timetable.component.label.DateDisplay')),
          timetableTitle: connectionFactory.editableText(translation.t('bookings.daily-timetable.component.label.TimetableHeading')),
          leftButton: connectionFactory.link(translation.t('bookings.daily-timetable.component.label.ArrowButton')),
          rightButton: connectionFactory.link(translation.t('bookings.daily-timetable.component.label.ArrowButton')),
          slotRepeater: connectionFactory.repeater(translation.t('bookings.daily-timetable.component.label.ClassesAvailable')),
          slotContainer: connectionFactory.repeaterItem(translation.t('bookings.daily-timetable.component.label.ClassDetails')),
          daysToolbar: connectionFactory.repeater(translation.t('bookings.daily-timetable.component.label.WeekDisplay')),
          dayItem: connectionFactory.repeaterItem(translation.t('bookings.daily-timetable.component.label.DayContainer')),
          day: connectionFactory.uneditableText(translation.t('bookings.daily-timetable.component.label.DayText')),
          dayPickerButton: connectionFactory.linkDisabledText(translation.t('bookings.daily-timetable.component.label.DateButton')),
          statebox: connectionFactory.stateBox(translation.t('bookings.daily-timetable.component.label.StateBox')),
          loaderImage: connectionFactory.loaderImage(translation.t('bookings.daily-timetable.component.label.ClassesLoading')),
          time: connectionFactory.uneditableText(translation.t('bookings.daily-timetable.component.label.Time')),
          duration: connectionFactory.uneditableText(translation.t('bookings.daily-timetable.component.label.Duration')),
          serviceName: connectionFactory.uneditableText(translation.t('bookings.daily-timetable.component.label.ServiceName')),
          staffMember: connectionFactory.uneditableText(translation.t('bookings.daily-timetable.component.label.StaffText')),
          spots: connectionFactory.uneditableText(translation.t('bookings.daily-timetable.component.label.Availability')),
          servicesDivider: connectionFactory.divider(translation.t('bookings.daily-timetable.component.label.Separator')),
          messageText: connectionFactory.editableText(translation.t('bookings.daily-timetable.component.label.EmptyState'), '2af923bc-c554-4877-b9c7-569f66041543'),
          messageBox: connectionFactory.textBox(translation.t('bookings.daily-timetable.component.label.EmptyStateContainer')),
          noServicesDivider: connectionFactory.divider(translation.t('bookings.daily-timetable.component.label.Separator')),
          [SlotRepeaterState.LIST]: connectionFactory.stateBoxState(translation.t('bookings.daily-timetable.component.label.ClassesAvailable')),
          [SlotRepeaterState.LOADING]: connectionFactory.stateBoxState(translation.t('bookings.daily-timetable.component.label.ClassesLoading')),
          [SlotRepeaterState.NO_CLASSES]: connectionFactory.stateBoxState(translation.t('bookings.daily-timetable.component.label.NoClasses'))
        }).build();

      return {
        controllersStageData: {
          [DAILY_TIMETABLE_WIDGET_CONTROLLER_ID]: {
            default: mainAppConnection
          }
        },
        pages: {
          pageActions: {
            default: [
              'Pages_Actions_Page_Rename',
              {
                title: translation.t('bookings-pages.page.delete'),
                event: deletePageEvent,
                icon: 'deleteAction',
                type: 'page_remove',
              }
            ],
            bookCheckoutPage:  []
          },
          pageSettings: {
            default: [
                {
                  title: translation.t('bookings-pages.tabs.page-info'),
                  helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                  type: 'page_info'
                },
                {
                  title: translation.t('bookings-pages.tabs.layout'),
                  type: 'layout'
                },
                {
                  title: translation.t('bookings-pages.tabs.permissions'),
                  type: 'permissions'
                },
                {
                  title: translation.t('bookings-pages.tabs.seo'),
                  type: 'seo'
                }
              ],
            bookCheckoutPage: [
                {
                  title: translation.t('bookings-pages.tabs.page-info'),
                  url: `https://bookings.wixapps.net/bookings-widget/book-checkout-page-info?locale=${locale}&instance=${instance}`,
                  helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
                  type: 'page_info'
                },
                {
                  title: translation.t('bookings-pages.tabs.layout'),
                  type: 'layout'
                },
                {
                  title: translation.t('bookings-pages.tabs.permissions'),
                  type: 'permissions'
                }
              ]
          },
          applicationSettings: {
            default: {
              displayName: translation.t('bookings-pages.title'),
              helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33'
            }
          },
          applicationActions: {
            default: {
              defaultValues: [{
                title: translation.t('bookings-pages.actions.manage'),
                event: manageBookingsEvent,
                icon: 'settingsAction'
              },
              {
                title: translation.t('bookings-pages.actions.delete'),
                event: deleteBookingsEvent,
                icon: 'deleteRadio'
              }]
            }
          },
          pageDescriptors: {
            default: {
              icon: 'bookingPageType',
              orderIndex: 2
            },
            bookCheckoutPage: {
              icon: 'bookingPageType',
              orderIndex: 1
            }
          },
        }
      }
  },
    onEvent: async ({eventType, eventPayload}, editorSDK) => {
      function openManageBookings() {
        editorSDK.editor.openDashboardPanel(appToken, {url: bookingsDashboardPanel, closeOtherPanels: false});
      }

      function focusOnStateBox(widgetComponentRef) {
        getStateBoxByBookingsAppBuilderWidget(editorSDK, appToken, widgetComponentRef).then(stateBoxComponentRef => {
          editorSDK.editor.selection.selectComponentByCompRef(appToken, {compsToSelect: [stateBoxComponentRef]})
        });
      }

      function getWidgetComponentRef(componentRef) {
        getBookingsAppBuilderWidgetByChildComponentRef(editorSDK, appToken, componentRef).then(stateBoxComponentRef => {
          editorSDK.editor.selection.selectComponentByCompRef(appToken, {compsToSelect: [stateBoxComponentRef]})
        });
      }

      switch (eventType) {
        case widgetGfppClicked:
          if (eventPayload.id === manageBookingsEvent) {
            openManageBookings();
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(eventPayload.componentRef)
          }
          break;
        case componentGfppClicked:
          if (eventPayload.id === manageBookingsEvent) {
            openManageBookings();
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          }
          break;
        case manageBookingsEvent:
          openManageBookings();
          break;
        case manageStateEvent:
          focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          break;
        case deleteBookingsEvent:
          const bookingsPages = await getAllBookingsPages(editorSDK, appToken);
          const essentialPage = bookingsPages.find(page => page.tpaPageId === PageId.BOOKINGS_LIST);
          await removePage(editorSDK, appToken, essentialPage.id);
          break;
        case deletePageEvent:
          await removePage(editorSDK, appToken, eventPayload.pageRef.id);
          break;
        case pageChangedEvent:
          const pageData = await getPageData(sdk, appToken, eventPayload.pageRef);
          if (pageData.tpaPageId === PageId.SCHEDULER && isProposeMigrationNeeded && !(await isBookingsSplitted(editorSDK))) {
            await proposeMigration(sdk, appToken, locale, instance, translation);
          }
          break;
      }
    },
    handleAction: async (args) => {
      const type = args.type, payload = args.payload;
      try {
        switch (type) {
          case 'migrate':
            // if (isAdi) {
            //   return Promise.reject('Site built with ADI - Bookings migration blocked.');
            // }
            if (!(await isBookingsSplitted(sdk))) {
              return handleMigration(sdk, appToken, translation, !isAdi);
            } else {
              await handleLightMigration(sdk, appToken);
              return Promise.resolve();
            }
          case 'appInstalled':
            switch (payload.appDefinitionId) {
              case MEMBERS_AREA_DEF_ID: {
                return addBookingsMembersArea(sdk, appToken);
              }
              default:
                return Promise.resolve();
            }
          case 'removeApp':
            return onRemoveApp(sdk, appToken);
          default:
            return Promise.resolve();
        }
      } catch (e) {
        Promise.reject(e);
        throw e;
      }
    },
    getControllerPresets: () => {
      return Promise.resolve([]);
    }
  };
};
